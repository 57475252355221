<template>
  <vxe-column
    :field="dynamicField.field_name"
    :title="dynamicField.title"
    :edit-render="editRender"
    show-overflow="ellipsis"
    width="300"
  >
    <template #default="{ row }">
      <span>{{ formatValue(row[dynamicField.field_name]) }}</span>
    </template>
    <template #edit="scope">
      <vxe-select v-model="scope.row[dynamicField.field_name]" transfer :multiple="isMultiple">
        <vxe-option v-for="item in dynamicField.extra_options.items" :key="item.key" :value="item.key"
                    :label="item.value"/>
      </vxe-select>
    </template>
  </vxe-column>
</template>

<script>
export default {
  name: "Field",
  props: {
    dynamicField: {
      type: Object,
      require: true,
    },
    tableRef: {
      require: true,
    }
  },
  data: () => ({
    editRender: {
      autofocus: '.myinput'
    },
  }),
  computed: {
    isMultiple() {
      return this.dynamicField.extra_options.multiple
    }
  },
  methods: {
    formatValue(value) {
      if (value) {
        if (!this.isMultiple) {
          const label = this.dynamicField.extra_options.items.find((item) => {
            return item.key === value;
          })

          if (label !== undefined) {
            return label.value;
          }
        } else {
          var selectedValues = [];
          this.dynamicField.extra_options.items.forEach((item) => {
            if (value.includes(item.key)) {
              selectedValues.push(item.value);
            }
          })

          if (selectedValues.length > 0) {
            return selectedValues.join(', ');
          }
        }
      }

      return "-";
    }
  }
}
</script>

<style scoped>

</style>
